<template>
  <div ref="relativeDiv" style="position: relative;">
    <transition name="fade">
      <div
        class="default-measures wrapper-div transitionable"
        ref="wrapperDiv"
        v-show="isVisible"
        @mousemove="cursorStyle"
        @mousedown="resizeStart"
        :style="computedWrapperStyle"
      >
        <div class="banner-container d-flex flex-row align-center justify-space-between">
          <ClosedSuggestions v-if="!isSuggestionsOpen" :class="['action-button', 'icon', isRtl ? 'flip' : '']"
                             @click="setSuggestionOpen(true)"/>
          <OpenedSuggestions v-else :class="['action-button', 'icon', isRtl ? 'flip' : '']" @click="setSuggestionOpen(false)"/>

          <div class="logo-container spacer d-flex flex-row align-center"
               @mousedown="dragStart">
            <div class="genie-title pa-2">BizAI</div>
            <span class="beta-chip">Beta</span>
          </div>

          <div class="popup-controllers pa-3">
            <!--    eslint-disable-next-line vuejs-accessibility/click-events-have-key-events       -->
            <div class="action-button text-action-button" @click="onClearChat">
              <ClearChatIcon class="align-self-center" />
              <span class="pa-2">
                {{ $t('bizai.topbar.clear_chat') }}
              </span>
            </div>
            <!--      Vertical line breaker -->
            <div class="divider"/>
            <div class="display-controllers">
              <CollapseChat v-if="isExpanded" class="action-button icon"
                            @click="setExpanded(false)"/>
              <ExpandChat v-else class="action-button icon" @click="setExpanded(true)"/>
              <CloseChat class="action-button icon" @click="onCloseChat"/>
            </div>
          </div>
        </div>
        <iframe id="bizai-frame" title="bizai-title"
                ref="bizaiIframe"
                v-if="isLaunched"
                :src="srcIframe"
                class="bizai-frame"
        ></iframe>
      </div>

    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import eventListenerService from '@/utils/eventCommunicationUtils/eventListenerService';
import colorUtil from '@vcita/design-system/utils/colorUtil';

export default {
  name: 'BizAI',
  components: {
    ClearChatIcon: () => import('@/modules/bizai/assets/clear-chat.svg'),
    ClosedSuggestions: () => import('@/modules/bizai/assets/close-suggestions.svg'),
    OpenedSuggestions: () => import('@/modules/bizai/assets/open-suggestions.svg'),
    ExpandChat: () => import('@/modules/bizai/assets/expand-chat.svg'),
    CollapseChat: () => import('@/modules/bizai/assets/collapse-chat.svg'),
    CloseChat: () => import('@/modules/bizai/assets/close.svg'),
  },
  async created() {
    // IMPORTANT: Keep the order of the I18n module and the apps loading
    await this.loadAppsByType('ai_assistant');
    await this.addI18nModule('bizai-pov');

    // Listen for bizai events from angular iframe (old layout)
    eventListenerService.addListener('bizai', (e) => {
      const { event, data } = e;
      if (event?.includes('bizai')) {
        switch (data?.command) {
          case 'toggle-display':
            this.toggleVisibility();
            break;
          case 'set-suggestions-open':
            this.isSuggestionsOpen = true;
            break;
          default:
            break;
        }
      }
    });
    window.bizaiPrompt = this.triggerStarterPrompt.bind(this);
  },
  mounted() {
    this.observer = new ResizeObserver(() => {
      this.maxWidth = window.innerWidth - 268;
      this.setMaxHeight();
    });
    this.observer.observe(document.body);
    const starterPrompt = this.$route.query.bizai_starter_prompt;
    if (starterPrompt) {
      this.triggerStarterPrompt(starterPrompt, 'url');
    }
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  data() {
    const resizeZonePx = 8;
    return {
      width: 432,
      widthUnit: 'px',
      starterPrompt: null,
      dragProps: null,
      resizeProps: null,
      resizeStartPosX: null,
      resizeStartPosY: null,
      resizeZonePx,
      minWidth: 386 + 2 * resizeZonePx, // 386 for iframe
      maxWidth: window.innerWidth - 268,
      minHeight: 624,
      maxHeight: window.innerHeight - 66,
      dragOverlay: null,
      resizeOverlay: null,
      observer: null,
      isSuggestionsOpen: false,
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters('CommonStore', ['isRtl']),
    ...mapGetters('AuthStore', ['staffUid', 'businessUid']),
    ...mapGetters('BizaiStore', ['isVisible', 'isLaunched']),
    ...mapGetters('BusinessStore', ['secondaryColor', 'primaryColor', 'textColor']),
    ...mapGetters('AppsStore', ['availableAppByCodeName']),
    entrySrc() {
      return this.availableAppByCodeName({ appCodeName: 'genie' })?.app_host ?? '/apps/bizai/app/';
    },
    srcIframe() {
      let url = '';
      try {
        url = new URL(this.entrySrc);
        // also handles RAP without relative in entrySrc
        url.hostname = window.location.hostname;
      } catch (e) {
        // case RAP with relative in entrySrc
        url = new URL(window.location.origin + this.entrySrc);
      }
      url.searchParams.set('staff_uid', this.staffUid);
      url.searchParams.set('business_uid', this.businessUid);
      url.searchParams.set('mode', 'popup');
      url.searchParams.set('mobile', this.$isMobile?.().toString() ?? 'false');
      url.searchParams.set('language', this.$i18n.locale);
      const branding = {
        primaryColor: colorUtil.rgbToHex(this.primaryColor),
        secondaryColor: colorUtil.rgbToHex(this.secondaryColor),
        textColor: colorUtil.rgbToHex(this.textColor),
      };
      url.searchParams.set('branding', JSON.stringify(branding));
      if (this.starterPrompt) {
        url.searchParams.set('starter_prompt', this.starterPrompt);
      }
      return url.toString();
    },
    computedWrapperStyle() {
      let size = 432;
      let unit = 'px';
      if (this.isSuggestionsOpen) {
        size += 265;
      }
      if (this.isExpanded) {
        size = 100;
        unit = '%';
      }
      return {
        padding: `${this.resizeZonePx}px`,
        minWidth: `${this.minWidth}px`,
        maxWidth: `${this.maxWidth}px`,
        minHeight: `${this.minHeight}px`,
        maxHeight: `${this.maxHeight}px`,
        width: `${size}${unit}`,
      };
    },
  },
  methods: {
    ...mapActions('BizaiStore', ['toggleVisibility', 'setVisibility']),
    ...mapActions('AppsStore', ['loadAppsByType']),
    triggerStarterPrompt(prompt, uiElementSource) {
      // If the iframe is not launched yet, store the prompt, so it would be sent as a query param
      // this is a workaround for the fact that the iframe is not yet loaded when the prompt is called
      if (!this.isLaunched) {
        this.starterPrompt = prompt;
      } else {
        this.isExpanded = false;
        this.sendPostMessage('click-suggestion-prompt', {
          prompt,
          info: {
            uiElementSource,
            location: window.location.href,
          },
        });
      }

      this.setVisibility(true);
    },
    sendPostMessage(eventName, data) {
      const frame = this.$refs.bizaiIframe;
      if (!frame) {
        return;
      }
      frame.contentWindow.postMessage({
        source: 'pov-wrapper',
        event: eventName,
        data,
      }, '*');
    },
    setSuggestionOpen(isOpen) {
      this.isSuggestionsOpen = isOpen;
      this.sendPostMessage('set-suggestions-open', { state: isOpen });
    },
    onClearChat() {
      this.sendPostMessage('click-clear-chat');
    },
    setExpanded(isExpanded) {
      this.isExpanded = isExpanded;
      this.sendPostMessage('set-expanded', { state: isExpanded });
    },
    onCloseChat() {
      this.setVisibility(false);
      this.sendPostMessage('click-close-chat');
    },
    createOverlay(id, { cursor, backgroundColor, opacity } = {}) {
      const overlay = document.createElement('div');
      overlay.setAttribute('id', id);
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.right = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.zIndex = '1100';
      overlay.style.cursor = cursor ?? 'default';
      overlay.style.backgroundColor = backgroundColor ?? 'rgba(0, 0, 0, 0)';
      overlay.style.opacity = opacity ?? '0';
      document.body.appendChild(overlay);
      return overlay;
    },
    dragStart() {
      const overlay = this.createOverlay('drag-overlay', { cursor: 'move' });
      this.dragOverlay = overlay;

      overlay.addEventListener('mousemove', this.onDrag);
      overlay.addEventListener('mouseup', this.dragEnd);
      overlay.addEventListener('mouseleave', this.dragEnd);
    },
    onDrag(e) {
      const { wrapperDiv } = this.$refs;
      const rect = wrapperDiv.getBoundingClientRect();
      const parentRect = this.$refs.relativeDiv.getBoundingClientRect();

      if (!this.dragProps) {
        this.dragProps = {
          offsetX: e.clientX - rect.left,
          offsetY: e.clientY - rect.top,
        };
      }
      const { offsetX, offsetY } = this.dragProps;
      const movingUp = e.movementY < 0;
      const movingRight = e.movementX > 0;

      const currentTop = parseInt(wrapperDiv.style.top || '0', 10);
      const currentRight = parseInt(wrapperDiv.style.right || '0', 10);
      let newTop = currentTop;
      let newRight = currentRight;

      // New position, movable only when passing the init offset
      if ((movingUp && e.clientY - rect.top < offsetY) || (!movingUp && e.clientY - rect.top > offsetY)) {
        newTop = currentTop + e.movementY;
      }
      if ((movingRight && e.clientX - rect.left > offsetX) || (!movingRight && e.clientX - rect.left < offsetX)) {
        newRight = currentRight - e.movementX;
      }

      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;

      // Prevent moving beyond the top/bottom boundaries
      const maxTop = Math.min(newTop, viewportHeight - rect.height - parentRect.top);
      const minTop = -parentRect.top;
      newTop = Math.max(minTop, maxTop);
      // Prevent moving beyond the right/left boundaries
      newRight = Math.max(0, Math.min(newRight, viewportWidth - rect.width));

      wrapperDiv.style.top = `${newTop}px`;
      wrapperDiv.style.right = `${newRight}px`;
    },
    dragEnd() {
      const overlay = this.dragOverlay;
      this.dragProps = null;
      if (!overlay) {
        return;
      }
      overlay.removeEventListener('mousemove', this.onDrag);
      overlay.removeEventListener('mouseup', this.dragEnd);
      overlay.removeEventListener('mouseleave', this.dragEnd);
      overlay.remove();
      this.dragOverlay = null;
    },
    cursorEdges(e) {
      const threshold = this.resizeZonePx;
      const wrapperRect = this.$refs.wrapperDiv.getBoundingClientRect();

      const nearTop = e.clientY - wrapperRect.top < threshold;
      const nearBottom = wrapperRect.bottom - e.clientY < threshold;
      const nearLeft = e.clientX - wrapperRect.left < threshold;
      const nearRight = wrapperRect.right - e.clientX < threshold;

      return {
        nearTop,
        nearBottom,
        nearLeft,
        nearRight,
      };
    },
    cursorStyle(e) {
      let cursorStyle = 'default';
      const {
        nearTop, nearBottom, nearLeft, nearRight,
      } = this.cursorEdges(e);

      if ((nearTop && nearRight) || (nearBottom && nearLeft)) cursorStyle = 'nesw-resize';
      else if ((nearTop && nearLeft) || (nearBottom && nearRight)) cursorStyle = 'nwse-resize';
      else if (nearBottom || nearTop) cursorStyle = 'ns-resize';
      else if (nearRight || nearLeft) cursorStyle = 'ew-resize';

      this.$refs.wrapperDiv.style.cursor = cursorStyle;
    },
    resizeStart(e) {
      this.resizeProps = this.cursorEdges(e);
      if (Object.values(this.resizeProps).every((v) => !v)) {
        return;
      }

      const overlay = this.createOverlay('resize-overlay', {
        cursor: this.$refs.wrapperDiv.style.cursor,
      });
      this.resizeOverlay = overlay;
      this.$refs.wrapperDiv.classList.remove('transitionable');

      overlay.addEventListener('mousemove', this.onResize);
      overlay.addEventListener('mouseup', this.resizeEnd);
      overlay.addEventListener('mouseleave', this.resizeEnd);
    },
    onResize(e) {
      const { wrapperDiv } = this.$refs;
      const {
        nearTop, nearBottom, nearLeft, nearRight,
      } = this.resizeProps;
      const rect = wrapperDiv.getBoundingClientRect();

      const currentHeight = rect.height;
      const currentWidth = rect.width;
      const currentTop = parseInt(wrapperDiv.style.top || '0', 10);
      const currentRight = parseInt(wrapperDiv.style.right || '0', 10);

      let newHeight = currentHeight;
      let newWidth = currentWidth;
      let newTop = currentTop;
      let newRight = currentRight;

      const movingUp = e.movementY < 0;
      const movingRight = e.movementX > 0;

      // Calculate new size and positions
      if (nearTop) {
        if ((movingUp && e.clientY < rect.y) || (!movingUp && e.clientY > rect.y)) {
          const desiredHeight = currentHeight - e.movementY;
          if (desiredHeight > this.minHeight && desiredHeight < this.maxHeight) {
            newHeight = desiredHeight;
            newTop = currentTop + e.movementY;
          }
        }
      } else if (nearBottom) {
        if ((movingUp && e.clientY < rect.y + rect.height) || (!movingUp && e.clientY > rect.y + rect.height)) {
          newHeight = currentHeight + e.movementY;
        }
      }

      if (nearLeft) {
        if ((movingRight && e.clientX > rect.x) || (!movingRight && e.clientX < rect.x)) {
          newWidth = currentWidth - e.movementX;
        }
      } else if (nearRight) {
        if ((movingRight && e.clientX > rect.x + rect.width) || (!movingRight && e.clientX < rect.x + rect.width)) {
          const desiredWidth = currentWidth + e.movementX;
          if (desiredWidth >= this.minWidth && desiredWidth <= this.maxWidth) {
            newWidth = desiredWidth;
            newRight = currentRight - e.movementX;
          }
        }
      }

      wrapperDiv.style.height = `${newHeight}px`;
      wrapperDiv.style.width = `${newWidth}px`;
      wrapperDiv.style.top = `${newTop}px`;
      wrapperDiv.style.right = `${newRight}px`;
    },
    resizeEnd() {
      this.$refs.wrapperDiv.classList.add('transitionable');
      this.resizeProps = null;
      const overlay = this.resizeOverlay;
      if (!overlay) {
        return;
      }
      overlay.removeEventListener('mousemove', this.onResize);
      overlay.removeEventListener('mouseup', this.resizeEnd);
      overlay.removeEventListener('mouseleave', this.resizeEnd);
      overlay.remove();
      this.resizeOverlay = null;
    },
    setMaxHeight() {
      const { relativeDiv } = this.$refs;
      if (relativeDiv) {
        this.maxHeight = window.innerHeight - relativeDiv.getBoundingClientRect().bottom;
      } else {
        this.maxHeight = window.innerHeight - 66 - 48;
      }
    },
  },
  watch: {
    isVisible() {
      this.setMaxHeight();
    },
  },
};
</script>

<style lang="scss">
@import "styles/variables.scss";

.banner-container {
  background: #FFF;
  height: 48px;
  padding-inline-start: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.11);
  position: relative;
  z-index: 2;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  .logo-container {
    cursor: move;

    .genie-title {
      color: #212121;
      font-size: 19px;
      font-family: Montserrat;
      font-weight: 700;
      line-height: 32px;
    }
  }

  .action-button {
    color: #757575;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }

  .text-action-button {
    color: var(--gray-darken-5);
    display: inline-flex;
  }

  .divider {
    width: 1px;
    border-right: 1px solid #E0E0E0;
    height: 100%;
    max-height: 16px;
  }

  .icon {
    margin: 8px;
  }

  .flip {
    transform: scaleX(-1);
  }

  .popup-controllers {
    height: inherit;
    align-items: center;
    gap: 8px;
    display: inline-flex;

    .display-controllers {
      display: flex;
      gap: 8px;
    }
  }
}

.beta-chip {
  border-radius: var(--size-value5);
  font-weight: var(--font-weight-large2);
  font-size: 10px;
  height: var(--size-value4);
  width: var(--size-value10);
  background-color: var(--orange);
  color: white;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  text-transform: uppercase;
  letter-spacing: 0.50px;
}

/* Vue's transition tag classes */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.bizai-frame {
  border: unset;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--neutral-lighten-3, #F8F9FB);
  box-shadow: 0 5px 24px 0 rgba(0, 0, 0, 0.12), 2px 13px 19px 2px rgba(0, 0, 0, 0.14), 0 7px 8px -4px rgba(0, 0, 0, 0.20);
  width: 100%;
  height: calc(100% - 48px);
}

.wrapper-div {
  position: absolute;
  right: 0;
  z-index: 1000;
}

.transitionable {
  transition: width 0.3s ease-out, opacity 0.3s;
}

.default-measures {
  height: calc(100vh - 66px);
}
</style>
