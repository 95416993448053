// const baseRoute = 'api.intandem.com/StaffWidgetsBoard?type=<type>';

// const getBoard = async () => gatewayService.get(`${baseRoute}/accounts`).then((res) => res.data.data);
const getBoard = (type) => Promise.resolve(
  [
    {
      uid: '123',
      type,
      board_layout_code_name: 'MainAndSideBar3Columns',
      sections: [
        {
          uid: '123',
          code_name: 'side',
          widgets: [
            {
              uid: 'widget1',
              name: 'Payments',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 1,
                max_height: 3,
                height: 3,
                min_width: 2,
                max_width: 4,
                width: 1,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'main',
              name: 'Clients',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 1,
                max_height: 1,
                height: 1,
                min_width: 2,
                max_width: 4,
                width: 1,
              },
              component: {
                name: 'Clients',
                config: {},
              },
            },
          ],
        },
        {
          uid: '456',
          code_name: 'main',
          widgets: [
            {
              uid: 'widget3',
              name: 'Scheduling',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 6,
                width: 4,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'widget4',
              name: 'Scheduling 2',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 4,
                width: 2,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'widget5',
              name: 'Scheduling 3',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 4,
                width: 2,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'widget6',
              name: 'Scheduling 4',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 6,
                width: 2,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'widget7',
              name: 'Scheduling 5',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 4,
                width: 4,
              },
              component: {
                name: 'Counter',
                config: {
                  url: 'https://example.com/counter',
                  value: '$.data.counter',
                },
              },
            },
            {
              uid: 'widget8',
              name: 'More Campaigns',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 6,
                width: 6,
              },
              component: {
                name: 'Clients',
                config: {},
              },
            },
            {
              uid: 'widget8',
              name: 'Campaigns',
              app_code_name: 'QuickBooks',
              dimensions: {
                min_height: 2,
                max_height: 2,
                height: 1,
                min_width: 1,
                max_width: 2,
                width: 2,
              },
              component: {
                name: 'Clients',
                config: {},
              },
            },
          ],
        },
      ],
    },
  ],
);

export default {
  getBoard,
};
