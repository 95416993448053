import BusinessDateFormats from '@/modules/common/filters/BusinessDateFormats';
import { CURRENCIES } from '@/constansts/currency';
import pick from 'lodash/pick';
import CrmConstants from '@/modules/crm/constants/crmConstants';

const findCalculatedValue = (client, fieldProps) => {
  const [firstKey, ...restKeys] = fieldProps;
  if (restKeys.length === 0) {
    return client[firstKey];
  }
  return client[firstKey] ? findCalculatedValue(client[firstKey], restKeys) : null;
};

const mapCalculatedField = (headerItem, client) => {
  const fieldProps = headerItem.identifier.split('.');
  const calculatedValue = findCalculatedValue(client, fieldProps);
  const currency = CURRENCIES[fieldProps[fieldProps.length - 1]];

  switch (headerItem.type) {
    case 'NextScheduledBooking':
      // eslint-disable-next-line no-case-declarations
      const epochTime = calculatedValue ? calculatedValue * 1000 : null;
      // eslint-disable-next-line no-case-declarations
      const convertedDateToString = new Date(epochTime).toLocaleDateString();
      return calculatedValue ? BusinessDateFormats.dateNoCurrentYear(convertedDateToString) : null;
    case 'OpenPayments':
    case 'PendingEstimates':
    case 'ApprovedEstimates':
      return currency && calculatedValue ? currency.symbol + calculatedValue : calculatedValue;
    default:
      return calculatedValue;
  }
};

const mapCustomField = (headerItem, client, translations) => {
  const fieldUid = headerItem.identifier.split('.')[1];
  const customFieldValue = client.custom_fields[fieldUid];
  switch (headerItem.type) {
    case 'SingleLine':
    case 'Name':
    case 'Identifier':
    case 'Phone':
    case 'DropDown':
      return customFieldValue;
    case 'Datepicker':
      return customFieldValue ? BusinessDateFormats.dateNoCurrentYear(customFieldValue) : null;
    case 'Checkbox':
    case 'OptIn':
      return customFieldValue ? translations.yes : translations.no;
    default:
      return customFieldValue;
  }
};

const mapDefaultField = (headerItem, client, translations) => {
  const tagMap = (tag) => ({ label: tag, small: true });
  switch (headerItem.identifier) {
    case 'matter_name':
      return { name: client.matter_name, uid: client.matter_uid, isDemoClient: client.client_type === 'demo_client' };
    case 'tags':
      return client.tag_list.map(tagMap);
    case 'last_activity_action':
      return translations.last_activity_action;
    case 'last_activity_time':
      return client.last_activity_time
        ? BusinessDateFormats.dateAndHour(client.last_activity_time) : null;
    case 'email':
      return client.contact_email;
    case 'created_at':
      return client.created_at ? BusinessDateFormats.dateNoCurrentYear(client.created_at) : null;
    case 'review_score':
      return client.review_score || 0;
    case 'contact_status':
      return client.status;
    case 'contact_assigned_staff_uid':
      return client.staff;
    case 'checkbox':
      return client.checkbox;
    default:
      return client[headerItem.identifier];
  }
};

const getMandatoryFields = (client) => ({
  photo_path: { imagePath: client.photo_path, name: client.contact_full_name, colorId: client.color_id || 2 },
  contact_full_name: client.contact_full_name,
  contact_first_name: client.contact_first_name,
  contact_last_name: client.contact_last_name,
  contact_uid: client.contact_uid,
});

const mapClientAndHeaders = (headers, client, translations) => {
  const clientObject = {};

  headers.forEach((headerItem) => {
    let currentValue;
    if (headerItem.identifier.includes('calculated_fields')) {
      currentValue = mapCalculatedField(headerItem, client);
    } else if (headerItem.identifier.includes('custom_fields')) {
      currentValue = mapCustomField(headerItem, client, translations);
    } else {
      currentValue = mapDefaultField(headerItem, client, translations);
    }
    clientObject[headerItem.identifier] = currentValue;
  });

  return { ...clientObject, ...getMandatoryFields(client) };
};

const mapClientsForBulkActionsFromRawData = (hasCustomFieldsFF, clients) => {
  let mappedClients;
  if (hasCustomFieldsFF) {
    mappedClients = clients.map((client) => ({
      matter_uid: client.matter_uid,
      full_name: client.contact_full_name,
      first_name: client.contact_first_name,
      last_name: client.contact_last_name,
      matter_name: client.matter_name,
      photo_path: client.photo_path,
      color_id: client.color_id || 2,
      uid: client.contact_uid,
      email: client.email,
      notifications_phone_normalized_value: client.notifications_phone_normalized_value,
    }));
  } else {
    mappedClients = clients.map((client) => ({ ...pick(client, CrmConstants.BULK_ACTION_MAPPING) }));
  }
  return mappedClients;
};
const mapClientsForBulkActions = (rawData, hasCustomFieldsFF, clients) => {
  let mappedClients;
  if (rawData) {
    mappedClients = mapClientsForBulkActionsFromRawData(hasCustomFieldsFF, clients);
  } else {
    mappedClients = clients.map((client) => ({
      matter_uid: client.matter_name.uid,
      full_name: hasCustomFieldsFF ? client.contact_full_name : client.full_name,
      first_name: hasCustomFieldsFF ? client.contact_first_name : client.first_name,
      last_name: hasCustomFieldsFF ? client.contact_last_name : client.last_name,
      matter_name: client.matter_name.name,
      photo_path: hasCustomFieldsFF ? client.photo_path.imagePath : client.contact_image.imagePath,
      color_id: hasCustomFieldsFF ? client.photo_path.colorId : client.contact_image.colorId,
      uid: hasCustomFieldsFF ? client.contact_uid : client.uid,
      email: client.email,
      notifications_phone_normalized_value: client.notifications_phone_normalized_value,
    }));
  }
  return mappedClients;
};

export default {
  mapClientAndHeaders,
  mapClientsForBulkActions,
};
