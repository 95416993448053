<template>
  <div v-if="topBarConfig.show" class="top-bar-wrapper">
    <VcTopMenuBar v-if="(isMobile || isMobileApp)" class="top-bar-container-mobile"
      :showBackButton="!!topBarConfig.showBackButton"
      :avatar="topBarConfig.avatar"
      :title="getTranslation(topBarConfig.title)"
      :subtitle="topBarConfig.subtitle"
      :logo="topBarConfig.logo"
      :firstButton="topBarConfig.firstButton"
      :secondButton="topBarConfig.secondButton"
      :bottomSheetActions="topBarConfig.bottomSheetActions"
      :textActionableButton="topBarConfig.textActionableButton"
      :textColor="textColor"
      @onBackClicked="backButtonClicked"
      @onAvatarClicked="avatarClicked"
      @onFirstButtonClicked="() => dispatchButtonClickedAction(topBarConfig.firstButton)"
      @onSecondButtonClicked="() => dispatchButtonClickedAction(topBarConfig.secondButton)"
      @onBottomSheetButtonClicked="onBottomSheetClicked"
      @onTextActionableButtonClicked="textActionableButtonClicked"
    ></VcTopMenuBar>

      <VcWideTopMenuBar
        v-else
        :showBackButton="!!topBarConfig.showBackButton"
        :back-button-label="$t('layout.back_button_label')"
        :value="showSearchResults"
        :name="userProfileDetails.displayName"
        :email="userProfileDetails.email"
        :color-id="userProfileDetails.colorId"
        :image-path="userProfileDetails.imagePath"
        :account-menu-items="accountDropDownItems"
        :upgrade-button-label="upgradeButtonLabel"
        :help-button-label="helpButtonDetails.label"
        :help-menu-items="helpDropDownDetails"
        :logout-label="logoutButtonDetails.label"
        :logout-icon="logoutButtonDetails.icon"
        :itemGroups="displaySearchResults"
        :throttleTime="800"
        :isSearching="isWaitingForSearchResponse"
        :isLoading="isLoading"
        :emptyStateTitle="emptyStateTitle"
        :emptyStateSubtitle="$t('layout.search_empty_state.subtitle')"
        :emptyStateImage="searchBarEmptyStateImage"
        :label="$t('layout.search_placeholder')"
        :placeholder="$t('layout.search_placeholder')"
        :isNotificationsDialogOpen="isPaneOpen"
        @onAccountMenuClick="onAccountMenuItemClick"
        @onUpgradeButtonClicked="onUpgradeButtonClicked"
        @onHelpButtonClicked="onHelpButtonClicked"
        @onHelpItemClicked="onHelpItemClicked"
        @onNotificationsBadgeClicked="onDesktopNotificationsBadgeClicked"
        @onNotificationsDropdownToggle="toggleNotificationsPane"
        @onSearch="onSearch" @onSeeAllClicked="onSeeAllClicked"
        @onItemClicked="onSearchItemClicked"
        @onDropdownChange="onDropdownChange"
        @onAccountMenuChange="onAccountMenuChange"
        @onAccountButtonClicked="onAccountButtonClicked"
        @onBackButtonClicked="backButtonClicked"
        :notification-badge="notificationsBadgeValue">
        <template #customMenuItems v-if="bizaiAvailable">
          <VcButton
            @click="toggleVisibility"
            flavor="secondary"
            ghost
            data-qa="bizai-button"
            style="width: 32px; height: 32px; padding: 6px;"
          >
            <template #prepend>
              <BizAIIcon/>
            </template>
          </VcButton>
        </template>
        <template #notificationsPane>
          <VcLayout column>
            <NotificationPaneTitle @notificationsSettingsButtonClick="openNotificationsSettings"/>
            <NotificationPane
              class="notifications-pane-wrapper"
              @onNotificationRowClicked="onNotificationRowClicked"/>
          </VcLayout>
        </template>
      </VcWideTopMenuBar>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import LayoutConstants from '@/constansts/layoutConstants';
import outgoingPostMessageUtils from '@/utils/eventCommunicationUtils/outgoingPostMessageUtils';
import SearchBarConstants from '@/constansts/SearchBarConstants';
import NotificationPane
  from '@/modules/notificationCenter/components/notificationPane/NotificationPane.vue';
import NotificationPaneTitle
  from '@/modules/notificationCenter/components/notificationPaneTitle/notificationPaneTitle.vue';
import MenuItemsUtils from '@/modules/layout/mainMenu/utils/menuItemsUtils';

export default {
  name: 'TopBarContainer',
  components: {
    BizAIIcon: () => import('@/modules/bizai/components/BizAiIcon.vue'),
    NotificationPaneTitle,
    NotificationPane,
  },
  props: {
    bizaiAvailable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isActionsDisabled: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.addI18nModule('notifications-pov');
    this.addI18nModule('layout-pov');
    this.initNotificationCenterBadge({ staffUid: this.staffUid });
  },
  data() {
    return {
      query: '',
      isPaneOpen: false,
    };
  },
  computed: {
    ...mapGetters('AuthStore', ['staffUid']),
    ...mapGetters('LayoutTopBarStore', ['topBarConfig']),
    ...mapGetters('CommonStore', ['isMobile', 'isMobileApp']),
    ...mapGetters('BusinessStore', ['textColor', 'attributes', 'directorySettings', 'isPartnerDirectory', 'hasHelpLinks']),
    ...mapGetters('StaffStore', ['staffData']),
    ...mapGetters('NotificationCenterStore', ['isNewCounter']),
    ...mapGetters('SearchBarStore', ['searchResults', 'showSearchResults', 'isWaitingForSearchResponse']),
    myAccountMenuWideFF() {
      return this.$hasFeature('my_account_menu_wide');
    },
    emptyStateTitle() {
      return this.$t('layout.search_empty_state.title', { query: this.query });
    },
    accountDropDownItems() {
      const myAccountRout = this.myAccountMenuWideFF ? 'app.setting_menu' : 'app.settings_page';
      const items = [
        {
          label: this.$t('common.navigation.account'),
          icon: 'icon-user',
          event: 'my-account',
          roles: ['admin', 'manager', 'marketing'],
          routingType: LayoutConstants.ROUTING_TYPES.angular,
          route: myAccountRout,
          routeData: { page: 'my_account' },
        },
        {
          label: this.$t('common.navigation.profile'),
          icon: 'icon-Suitcase',
          event: 'business-info',
          roles: ['admin', 'manager', 'marketing'],
          routingType: LayoutConstants.ROUTING_TYPES.angular,
          route: 'app.business_info_settings',
        },
        {
          label: this.$t('common.navigation.settings'),
          icon: 'icon-Settings',
          event: 'settings',
          roles: ['admin', 'manager', 'marketing'],
          routingType: LayoutConstants.ROUTING_TYPES.angular,
          route: 'app.settings',
          routeData: {},
        },
        {
          label: this.$t('common.navigation.staff'),
          icon: 'icon-User-vneck',
          event: 'staff',
          roles: ['admin'],
          routingType: LayoutConstants.ROUTING_TYPES.angular,
          route: 'app.settings_page',
          routeData: { page: 'staff' },
        },
        {
          label: this.$t('onboarding.new_onboarding.quick_btn_title'),
          icon: 'icon-Rocket_new',
          event: 'getting-started',
          roles: ['admin'],
          action: 'openChecklist',
        }];

      const staffRole = this.staffData.role || '';
      return items.filter((item) => item.roles.includes(staffRole));
    },
    isTealiumEnabled() {
      return this.$hasFeature('thryv_tealium_script');
    },
    shouldDisplayHelpDropDown() {
      return this.isPartnerDirectory && !this.isTealiumEnabled && this.hasHelpLinks;
    },
    userProfileDetails() {
      return {
        imagePath: this.staffData?.photo_path || this.attributes?.photo_path || '',
        email: this.staffData.email || '',
        displayName: this.staffData.display_name || '',
        colorId: this.staffData.color_id || 27,
      };
    },
    upgradeButtonLabel() {
      return this.attributes?.is_upgradeable ? this.getTranslation('common.words.upgrade') : '';
    },
    helpButtonDetails() {
      return {
        label: this.$t('common.navigation.help_button'),
        url: this.directorySettings.default_support_url,
      };
    },
    logoutButtonDetails() {
      return {
        label: this.$t('common.navigation.logout'),
        icon: 'icon-Logout_POV',
      };
    },
    helpDropDownDetails() {
      // this will be improved in VCITA-38769...
      const dropDownDetails = [
        {
          identifier: 0,
          event: 'help-dropdown-support',
          label: this.$t('common.navigation.support'),
          url: this.directorySettings.custom_support_url,
        },
        {
          identifier: 1,
          event: 'help-dropdown-faq',
          label: this.$t('common.navigation.general_faq'),
          url: this.directorySettings.faq_url,
        },
        {
          identifier: 2,
          event: 'schedule-with-cs',
          label: this.$t('common.help.schedule_with_cs'),
          url: this.directorySettings.help_links?.schedule_with_cs,
        },
        {
          identifier: 3,
          event: 'submit-support-req',
          label: this.$t('common.help.submit_support_req'),
          url: this.directorySettings.help_links?.submit_support_req,
        },
      ];

      return this.shouldDisplayHelpDropDown ? dropDownDetails.filter((item) => !!item.url) : [];
    },
    displaySearchResults() {
      return this.searchResults.map((itemGroup) => ({
        ...itemGroup,
        // eslint-disable-next-line localization/no-logic-in-translations
        label: (`${this.$t(`layout.search_categories.${itemGroup.id}`)} (${itemGroup.count})`),
        items: itemGroup.items.map((item) => {
          const searchItem = {
            ...item,
            // eslint-disable-next-line localization/no-logic-in-translations
            name: (item.type !== 'client' && item.type !== 'document') ? this.$t(`layout.result_names.${item.type}`) : item.name,
          };

          if (itemGroup.id === 'client') {
            searchItem.id = item.matter_uid;
            searchItem.contact_uid = item.id;
          }

          return searchItem;
        }),
      }));
    },
    searchBarEmptyStateImage() {
      // eslint-disable-next-line global-require
      return require('@/assets/images/search-bar/search-bar-empty-state.svg');
    },
    notificationsBadgeValue() {
      return this.isNewCounter > 99 ? '+99' : this.isNewCounter;
    },
  },
  watch: {
    showSearchResults() {
      this.setIsOverlayDisplay(this.showSearchResults);
    },
  },
  methods: {
    ...mapActions('AuthStore', ['onLogout']),
    ...mapActions('SearchBarStore', ['search', 'setShowSearchResults']),
    ...mapActions('AngularStore', ['setIsOverlayDisplay', 'toggleIsOverlayDisplay']),
    ...mapActions('NotificationCenterStore', ['initNotificationCenterBadge', 'handleTopbarBadgeClicked']),
    ...mapActions('BizaiStore', ['toggleVisibility']),
    ...mapActions('ModalStore', ['setForceModalMode']),
    onDropdownChange() {
      this.setShowSearchResults(false);
    },
    onSearchItemClicked(entity) {
      let data = {};
      if (entity.showMore) this.onSeeAllClicked(entity.type);
      else {
        data = { route: SearchBarConstants.ENTITY_STATES[entity.type], data: { uid: entity.type === 'client' ? entity.contact_uid : entity.id } };
        if (entity.matter_uid) data.data.matter_uid = entity.matter_uid;
        outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, data);
      }

      this.setShowSearchResults(false);
    },
    onSearch(query) {
      if (this.isActionsDisabled) return;
      this.query = query;
      if (query !== '') this.$track('Top bar search typed', { queryText: query });
      this.search(query);
    },
    onSeeAllClicked(entityType) {
      outgoingPostMessageUtils.post('navigate-to', { route: 'app.search', data: { type: entityType, query: this.query } });
      this.setShowSearchResults(false);
    },
    onBottomSheetClicked(clickedButtonData) {
      this.dispatchClickAction(
        this.topBarConfig.dispatchActionType,
        clickedButtonData,
        LayoutConstants.FRONTAGE_BOTTOM_SHEET_CLICK_ACTION,
      );
    },
    avatarClicked() {
      const avatar = this.topBarConfig.avatar || {};
      if (avatar.dispatchActionType === LayoutConstants.FRONTAGE_ACTION_TYPE) {
        outgoingPostMessageUtils.post(
          LayoutConstants.FRONTAGE_AVATAR_CLICK_ACTION,
          avatar.clickAction,
        );
      } else if (avatar.clickAction) {
        this.$store.dispatch(avatar.clickAction);
      }
    },
    backButtonClicked() {
      if (this.topBarConfig.dispatchActionType === LayoutConstants.FRONTAGE_ACTION_TYPE) {
        outgoingPostMessageUtils.post(LayoutConstants.FRONTAGE_BACK_BUTTON_CLICK_ACTION);
      } else {
        this.$router.handleBack();
      }
    },
    textActionableButtonClicked() {
      if (this.topBarConfig.textActionableButton.dispatchActionType === LayoutConstants.FRONTAGE_ACTION_TYPE) {
        outgoingPostMessageUtils.post(this.topBarConfig.textActionableButton.actionEvent);
      } else {
        this.$store.dispatch(this.topBarConfig.textActionableButton.actionEvent);
      }
    },
    dispatchButtonClickedAction(clickedButtonData) {
      if (this.isActionsDisabled) return;
      this.dispatchClickAction(
        this.topBarConfig.dispatchActionType,
        clickedButtonData,
        LayoutConstants.FRONTAGE_BUTTON_CLICK_ACTION,
      );
    },
    dispatchClickAction(dispatchActionType, clickedButtonData, frontageEvent) {
      if (clickedButtonData.dispatchActionType === LayoutConstants.POV_ACTION_TYPE) {
        if (clickedButtonData.actionEvent) {
          this.$store.dispatch(clickedButtonData.actionEvent, clickedButtonData.data);
        }
        if (clickedButtonData.payload?.action) {
          this.$router.push(clickedButtonData.payload.target);
        }
      } else {
        outgoingPostMessageUtils.post(frontageEvent, clickedButtonData);
      }
    },
    onUpgradeButtonClicked() {
      outgoingPostMessageUtils.post(LayoutConstants.FIRE_ACTION, { event_name: 'top-bar-upgrade-button-clicked' });
    },
    onHelpButtonClicked() {
      if (this.isTealiumEnabled) {
        window.CommandBar.toggleHelpHub();
      } else if (!this.shouldDisplayHelpDropDown && !this.directorySettings.is_whitelabel) {
        this.$track('Help Click', { from: 'Top bar' });
        window.open(`https://support.vcita.com/hc/${this.attributes?.locale.toLowerCase()}/signin`, '_blank');
      }
    },
    onHelpItemClicked(item) {
      window.open(item.url, '_blank');
    },
    async onDesktopNotificationsBadgeClicked() {
      if (this.isActionsDisabled) return;
      this.toggleNotificationsPane();
      await this.handleTopbarBadgeClicked();
    },
    toggleNotificationsPane() {
      this.isPaneOpen = !this.isPaneOpen;
      this.toggleIsOverlayDisplay();
    },
    onNotificationRowClicked() {
      this.isPaneOpen = false;
      this.setIsOverlayDisplay(false);
    },
    onAccountMenuItemClick(eventName) {
      if (eventName === 'logout') {
        this.onLogout();
      } else {
        this.setIsOverlayDisplay(false);
        if (this.isActionsDisabled) return;
        this.handleAccountMenuItemEvent(eventName);
      }
    },
    onAccountMenuChange() {
      this.setIsOverlayDisplay(false);
    },
    onAccountButtonClicked() {
      this.toggleIsOverlayDisplay();
    },
    handleAccountMenuItemEvent(eventName) {
      const isAngularDialog = eventName === 'getting-started';
      const isPovPage = !this.$route.meta.isAngular;
      if (isAngularDialog && isPovPage) {
        this.setForceModalMode(true);
      }
      const selectedItem = this.accountDropDownItems.find((item) => item.event === eventName);
      if (selectedItem.action) {
        this.fireAccountMenuItemAction(selectedItem, isAngularDialog && isPovPage);
      } else {
        this.handleAppRouting(selectedItem);
      }
    },
    handleAppRouting(selectedItem) {
      if (selectedItem.routingType === LayoutConstants.ROUTING_TYPES.angular) {
        this.angularRouting(selectedItem);
      } else if (selectedItem.routingType === LayoutConstants.ROUTING_TYPES.pov) {
        this.$router.push({ name: selectedItem.route, params: selectedItem.routeData });
      }
    },
    angularRouting(selectedItem) {
      const data = {
        route: selectedItem.route,
        data: selectedItem.routeData,
      };
      outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, data);
    },
    fireAccountMenuItemAction(item, hideBackground = false) {
      MenuItemsUtils.fireAction(item, hideBackground);
    },
    getTranslation(titleKey) {
      // eslint-disable-next-line localization/no-logic-in-translations
      return this.$t(titleKey);
    },
    openNotificationsSettings() {
      this.toggleNotificationsPane();
      outgoingPostMessageUtils.post(LayoutConstants.NAVIGATE_TO, { route: 'app.notification_settings' });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar-container-mobile {
  width: 100%;
}
.top-bar-wrapper{
  z-index: 1;
}
.notifications-pane-wrapper{
  width: 480px;
  min-height: 602px;
  height: 602px;
}
</style>
