import cookieUtil from '@/utils/cookieUtil';

const state = {
  liveApps: [],
  liveAppData: {}, // { [appId]: {timer, route: {route, query} } }
  displayedApp: undefined,
};

const mutations = {
  addLiveApp(state, appId) {
    if (!state.liveApps.includes(appId)) {
      state.liveApps = [...state.liveApps, appId];
    }
  },
  addAppRoute(state, { appId, appRoute, query }) {
    state.liveAppData = { ...state.liveAppData, [appId]: { route: { route: appRoute, query } } };
  },
  removeLiveApp(state, appId) {
    state.liveApps = [...state.liveApps.filter((liveApp) => liveApp.app_code_name !== appId)];
    delete state.liveAppData[appId];
    state.liveAppData = { ...state.liveAppData };
  },
  setDisplayedApp(state, appId) {
    state.displayedApp = appId;
  },
};

const actions = {
  addLiveApp({ commit, getters }, { appId, appRoute, query }) {
    commit('addAppRoute', { appId, appRoute, query });
    if (!getters.isAppLive(appId)) {
      commit('addLiveApp', appId);
    }
    commit('setDisplayedApp', appId);
  },
  removeLiveApp({ commit }, appId) {
    commit('removeLiveApp', appId);
  },
  setDisplayedApp({ commit }, appId) {
    commit('setDisplayedApp', appId);
  },
};

const populateParams = (param, getters) => {
  switch (param) {
    case 'impersonate':
      return cookieUtil.get('directory_login');
    case 'business_uid':
      return getters['AuthStore/businessUid'];
    case 'staff_role':
      return getters['StaffStore/staffRole'];
    case 'payment_permissions':
      // eslint-disable-next-line no-case-declarations
      const permissions = getters['StaffStore/permissions'];
      return permissions.includes('can_view_payments');
    case 'staff_uid':
      return getters['AuthStore/staffUid'];
    case 'language':
      return getters['BusinessStore/locale'];
    case 'package':
      // TODO: Do we have the data for the account package in POV?
      console.warn('package is still TBD');
      return '';
    // return Account.business.package;
    case 'brand_host':
      return window.location.host;
    default:
      console.warn('trying to populate default param');
      return '';
  }
};

const addAppQueryParams = (url, query, urlParams, getters) => {
  const appParams = Object.entries(query).filter((item) => item[0].toLowerCase().startsWith('app_')).map(([key, value]) => [key.toLowerCase().replace('app_', ''), value]);
  if (urlParams) {
    for (let i = 0; i < urlParams.length; i++) {
      appParams.push([urlParams[i], populateParams(urlParams[i], getters)]);
    }
    // In applications-service.js.coffee it looks like we always send this param
    if (!urlParams.includes('brand_host')) {
      appParams.push(['brand_host', populateParams('brand_host', getters)]);
    }
  }
  const params = new URLSearchParams(appParams);
  return new URL(`${url.href}?${params}`);
};
const getAppUrl = (app, getters) => {
  const displayedAppRoute = state.liveAppData[app.app_code_name]?.route;
  let url = new URL(app.app_host);

  if (displayedAppRoute) {
    if (displayedAppRoute.route) {
      const appUri = app.app_host.endsWith('/') ? app.app_host : `${app.app_host}/`;
      url = new URL(`${appUri}${displayedAppRoute.route}`);
    }
    return addAppQueryParams(url, displayedAppRoute.query, app.url_params, getters);
  }
  return url;
};

const getters = {
  liveApps: (state) => state.liveApps,
  isAppLive: (state) => (appId) => state.liveApps.find((app) => app === appId),
  displayedApp: (state) => state.displayedApp,
  getAppRoute: (state, _, rootState, rootGetters) => (id) => {
    const appData = rootState.AppStore.externalApplications.find((app) => app.app_code_name === id);
    return getAppUrl(appData, rootGetters);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
