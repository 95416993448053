import layoutService from '@/modules/widgetManager/services/layoutService';

const state = {
  layoutConfig: { type: undefined },
};

const getters = {
  layoutConfig: (state) => state.layoutConfig,
};

const mutations = {
  setLayoutConfig(state, layoutConfig) {
    state.layoutConfig = layoutConfig;
  },
};

const actions = {
  async getLayoutByType({ commit, state }, type) {
    let { layoutConfig } = state;
    if (state.layoutConfig.type !== type) {
      layoutConfig = await layoutService.getBoard(type);
      commit('setLayoutConfig', layoutConfig[0]);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
